
if (window.location.pathname.endsWith('/contact')) {
  const element = document.getElementById('mfp_phase_confirm');
  const displayStyle = window.getComputedStyle(element).display;
  const innerElement = document.getElementById('mfp_phase_confirm_inner');
  if (displayStyle === 'block' && innerElement && innerElement.children.length > 0) {
    const midashiElement = document.querySelector('p.midashi');
    if (midashiElement) {
      midashiElement.innerHTML = `入力内容をご確認の上「送信する」ボタンを押してください。<br>
          内容に不備がある場合は、「戻る」ボタンを押して修正してください。`;
    }
  }
}
