import SimpleParallax from "simple-parallax-js/vanilla";
const images = document.querySelectorAll('.parallax');
const images2 = document.querySelectorAll('.parallax2');
new SimpleParallax(images, {
  orientation: 'up',
  scale: 1.35,
  delay: 0,
});
new SimpleParallax(images2, {
  orientation: 'up',
  scale: 1.35,
  delay: 0,
});
