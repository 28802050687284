import 'slick-carousel';

$('.top-works-slides').slick({
  dots: false,
  arrows: true,
  infinite: false,
  autoplay: true,
  slidesToShow: 3,
  slidesToScroll: 1,
  variableWidth: true,
  pauseOnHover: false,
  pauseOnFocus: false,
  autoplaySpeed: 4000,
  speed: 800,
  responsive: [{

    breakpoint: 768,
    settings: {
      centerMode: true,
      infinite: true,
      slidesToShow: 1
    }

  }]
});

