import { gsap } from 'gsap';
import gsapCore from 'gsap/gsap-core';
import Scrolltrigger from 'gsap/ScrollTrigger';
gsap.registerPlugin(Scrolltrigger);
gsap.config({ nullTargetWarn: false, });

document.addEventListener("DOMContentLoaded", function () {
  Scrolltrigger.create({
    trigger: ".about-sec",
    start: "top top",
    onEnter: () => {
      $('.bg-movie').addClass('active');
      $('header').addClass('onlower');
    },
    onLeaveBack: () => {
      $('.bg-movie').removeClass('active');
      $('header').removeClass('onlower');
    }
  });
  Scrolltrigger.create({
    trigger: ".mv-sec",
    start: "center top",
    onEnter: () => {
      $('.bg-movie').addClass('onview');
    },
    onLeaveBack: () => {
      $('.bg-movie').removeClass('onview');
    }
  });
  /*
  ScrollTrigger.create({
    trigger: ".about-sec",
    start: "top top",
    onEnter: () => {
      $('header').addClass('onlower');
    },
    onLeaveBack: () => {
      $('header').removeClass('onlower');
    }
  });
*/

});

//text effect

$('.strip').each(function () {
  var $t = $(this),
    rows = $.trim($t.html()).split(/<br(?: class="sp_view")?>/);

  $t.html('');

  $.each(rows, function (i, val) {
    $('<span class="row"></span>').appendTo($t);

    var letters = $.trim(val).split('');

    $.each(letters, function (j, v) {
      v = (v == ' ') ? '&nbsp;' : v;
      $('<span>' + $.trim(v) + '</span>').appendTo($('.row:last', $t));
    });

  });
});

function triggerAnimation() {
  $('h1').addClass('onload');
  var i;
  for (i = 0; i < $('.strip span').length; i++) {
    (function (ind) {
      setTimeout(function () {
        $('.strip span:not(".row")').eq(ind).addClass('animate');
      }, ind * 3);
    })(i);
  }
}

setTimeout(triggerAnimation, 500);

//movie
$(window).on('load', function () {
  $('.bg-movie').find('figure').addClass('onload');
});
