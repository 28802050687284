import { gsap } from 'gsap';
import gsapCore from 'gsap/gsap-core';
import Scrolltrigger from 'gsap/ScrollTrigger';
gsap.registerPlugin(Scrolltrigger);
gsap.config({ nullTargetWarn: false, });

document.addEventListener("DOMContentLoaded", function () {
  Scrolltrigger.create({
    trigger: ".pagehead-sec",
    start: "center top",
    onEnter: () => {
      $('header').addClass('onlower');
    },
    onLeaveBack: () => {
      $('header').removeClass('onlower');
    }
  });
});
