import { gsap } from 'gsap';
const isTablet = /iPad|Android|Touch/.test(navigator.userAgent);

function initCursor() {
  if (window.innerWidth > 768 && !isTablet) {
    const cursor = document.getElementById("cursor"),
      follower = document.getElementById("follower"),
      link = $('a'),
      label = $('label'),
      button = $('button'),
      pos = { x: 0, y: 0 }, // カーソル要素の座標
      mouse = { x: pos.x, y: pos.y }, // マウスカーソルの座標
      speed = 0.5; // 0.01〜1 数値が大きほど早い

    // カーソルの座標を設定する
    const cursorSetX = gsap.quickSetter(cursor, "x", "px");
    const cursorSetY = gsap.quickSetter(cursor, "y", "px");

    // 遅延するカーソルの座標を設定する
    const followerSetX = gsap.quickSetter(follower, "x", "px");
    const followerSetY = gsap.quickSetter(follower, "y", "px");

    // マウスカーソル座標を取得する
    document.addEventListener("mousemove", function (event) {
      mouse.x = event.pageX;
      mouse.y = event.pageY;
    });

    gsap.ticker.add(function () {
      const dt = 1.0 - Math.pow(1.0 - speed, gsap.ticker.deltaRatio());

      pos.x += (mouse.x - pos.x) * dt;
      pos.y += (mouse.y - pos.y) * dt;
      cursorSetX(pos.x);
      cursorSetY(pos.y);
      followerSetX(pos.x);
      followerSetY(pos.y);
    });

    // マウスイベント（aタグ）
    for (var i = 0; i < link.length; i++) {
      link[i].addEventListener("mouseenter", function () {
        cursor.classList.add("is-active");
        follower.classList.add("is-active");
      });

      link[i].addEventListener("mouseleave", function () {
        cursor.classList.remove("is-active");
        follower.classList.remove("is-active");
      });
    }

    // マウスイベント(labelタグ）
    for (var i = 0; i < label.length; i++) {
      label[i].addEventListener("mouseenter", function () {
        cursor.classList.add("is-labelhover");
        follower.classList.add("is-labelhover");
      });

      label[i].addEventListener("mouseleave", function () {
        cursor.classList.remove("is-labelhover");
        follower.classList.remove("is-labelhover");
      });
    }
    // マウスイベント(buttonタグ）
    for (var i = 0; i < button.length; i++) {
      button[i].addEventListener("mouseenter", function () {
        cursor.classList.add("is-buttonhover");
        follower.classList.add("is-buttonhover");
      });

      button[i].addEventListener("mouseleave", function () {
        cursor.classList.remove("is-buttonhover");
        follower.classList.remove("is-buttonhover");
      });
    }

  }
}


window.addEventListener('resize', initCursor);
initCursor(); // 初回実行

