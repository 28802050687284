import { gsap } from 'gsap';
import gsapCore from 'gsap/gsap-core';
import Scrolltrigger from 'gsap/ScrollTrigger';
gsap.registerPlugin(Scrolltrigger);
gsap.config({ nullTargetWarn: false, });


//var flg;
let menuFlg = false;

$(document).on('click', '.menu-btn', function () {
  var $this = $(this);
  var $target = $this.next();
  $this.find('.menu-trigger').stop().toggleClass('active');

  if (menuFlg == false) {
    gsap.to($target, {
      autoAlpha: 1,
      duration: 0.3
    });
    menuFlg = true;
  } else {

    gsap.to($target, {
      autoAlpha: 0,
      duration: 0.3
    });
    $(this).find('.word').removeClass('active');
    menuFlg = false;
  }
  return false;
});



let lastWindowWidth = $(window).width();

function toggleFade() {
  if ($(window).width() > 768) {
    $(".acc-trigger").on("mouseenter", function () {
      $(this).find(".acc-target").stop(true, true).fadeIn(150);
    }).on("mouseleave", function () {
      $(this).find(".acc-target").stop(true, true).fadeOut(150);
    });
  } else {
    $(".acc-trigger").off("mouseenter mouseleave");
    $(".acc-target").hide();
  }
}

toggleFade();

$(window).on("resize", function () {
  let currentWindowWidth = $(window).width();
  if ((lastWindowWidth <= 768 && currentWindowWidth > 768) || (lastWindowWidth > 768 && currentWindowWidth <= 768)) {
    toggleFade();
  }
  lastWindowWidth = currentWindowWidth;
});
